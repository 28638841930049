import Video from "../../components/Video";
import logos from "../../assets/logos.png";
import Comments from "./Comments";
import volume from "../../assets/volume-high.png";

export default function Hero() {
  const currentDate = new Date();

  const formattedDate = currentDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <section className="min-h-screen">
      <article className="p-4 py-2 pb-11 m-auto max-w-6xl">
        <article className="flex flex-col font-bold text-custom-black text-2xl md:text-3xl">
          <Video />
        </article>
      </article>
    </section>
  );
}
